import { render, staticRenderFns } from "./Exclusion.vue?vue&type=template&id=81c4eb06&"
import script from "./Exclusion.vue?vue&type=script&lang=js&"
export * from "./Exclusion.vue?vue&type=script&lang=js&"
import style0 from "./Exclusion.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports